import MenuTopBar from "../menu/MenuTopBar";
import AccessTimeOutlinedIcon from "@material-ui/icons/AccessTimeOutlined";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import DoneIcon from "@material-ui/icons/Done";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { S3_MENU_FOLDER, S3_MENU_THUMBNAIL_FOLDER } from "../../store/constants";
import AppContainer from "../util/AppContainer";
import "./order-summary.css";
import { useDispatch, useSelector } from "react-redux";
import Dish from "../menu/Dish";
import "../dishBook/dishBook.css";
import "../menu/browse-menu-button.css";
import BottomBar from "../BottomBar";
import { bottomBarActions } from "../../store/bottomBarSlice";
import mixpanel from "mixpanel-browser";
import { orderActions } from "../../store/orderSlice";
import OrderStatusBadge from "../dishBook/orderStatusBadge";
import MapTrackingComponent from "../MapTracking/Index";
import { useEffect, useState } from "react";
import { updateUser } from "../../store/loginActions";
import { getExactTime, isWebApp } from "../../utils/helper";
// import { messaging } from "../../firebase";
import { getToken } from "@firebase/messaging";
import { loginActions } from "../../store/loginSlice";
import moment from "moment/moment";
import BackBtnSvg from "../../assets/ReactSvg/BackBtnSvg";
import RefreshIcon from "../../assets/ReactSvg/RefreshIcon";
import CallIcon from "../../assets/ReactSvg/CallIcon";
import CookingIcon from "../../assets/ReactSvg/CookingIcon";
import supportImg from "../../assets/support.png";
const GST_RATE = 5;

const OrderSummary = () => {
  const [isLoading, setIsLoading] = useState(false);
  const firebaseToken = useSelector(({ auth }) => auth.user.firebaseToken);
  const activeOrderDetails = useSelector((state) => state.auth.orderDetails);
 
  const singleOrderHistoryDetails = useSelector((state) => state.auth.singleOrderHistoryDetails);
  const orderDetails = !!singleOrderHistoryDetails && Object.keys(singleOrderHistoryDetails).length !== 0 ? singleOrderHistoryDetails : activeOrderDetails;
  const selectedDeliveryAddress = useSelector((state) => state.auth.selectedAddress);
  const deliveryAddress = orderDetails?.address || selectedDeliveryAddress;
  
  const estimatedTimeReducer = useSelector(
    (state) => state.order.estimatedTime
  );
  const orderDeliveredDate = useSelector(
    (state) => state.order.deliveredDate
  );
  const driverPhoneNumberReducer = useSelector(
    (state) => state.order.driverPhoneNumber
  );
  const driverNameReducer = useSelector((state) => state.order.driverName);

  const orderStatusReducer = useSelector((state) => state.order.orderStatus);

  const user = useSelector(({ auth }) => auth.user);
  const dispatch = useDispatch();

  // const generateToken = async () => {
  //   const token = await getToken(messaging, {
  //     vapidKey:
  //       "BBCPDwUEz-MJhJ8VJRBtFFTl5xM-EKOhPdyqkd5EB0Cn5OnNHJ0CrEcGig2sSJ2jqK6mHfyS7fLmU-EW99CW1-8",
  //   });
  //   if (token && user?.userid) {
  //     dispatch(updateUser(user, token, "token"));
  //   }
  // };

  const handleButtonClick = () => {
    setIsLoading(true);

    // Simulate a 3-second loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  const isOrderScheduled = !!orderDetails.scheduleTime && moment(orderDetails.scheduleTime).isValid() && orderDetails.scheduleTime !== "NOW"

  const deliveryDateTime = isOrderScheduled ? orderDetails.scheduleTime : orderDetails.createdAt;
  const deliveryTime = getExactTime(deliveryDateTime, isOrderScheduled ? 0 : orderDetails?.deliveryTime || 0, isOrderScheduled);

  // useEffect(() => {
  //   if (!firebaseToken && !isWebApp()) {
  //     if ("Notification" in window) {
  //       Notification.requestPermission().then((permission) => {
  //         if (permission === "granted") {
  //           if (Notification.permission === "granted") {
  //             generateToken();
  //           }
  //         } else if (permission === "denied") {
  //           console.log("Notification permission denied.");
  //         }
  //       });
  //     }
  //   }
  // }, []);

  useEffect(() => {
    return () => {
      dispatch(loginActions.setSingleOrderHistoryDetails(null));
    };
  }, []);

  const getOrderStatus = () => {
    switch (orderStatusReducer) {
      case "CREATED":
        return "Order Placed";
      case "CONFIRMED":
        return "Order Confirmed";
      case "READY":
        return "Order Ready";
      case "PICKED_UP":
        return "Order on the way";
      case "DELIVERED":
        return "Delivered";
      default:
        return "Preparing";
    }
  };

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  const onBackClick = () => {
    switchTab("chef");
  };

  var add_minutes = function (dt, minutes) {
    return new Date(dt.getTime() + minutes * 60000);
  };

  const phoneNumber = '+918655861798';

  const handleCallButtonClick = () => {
    window.open(`tel:${phoneNumber}`);
  };

  const handleCallDriverButtonClick = () => {
    window.open(`tel:+91${driverPhoneNumberReducer}`);
  };

  const onSupportClick = () => {
    let url = `https://wa.me/918655861798?text=Hey+Cuirato,+I+want+help+regarding+an+order+Number+${orderDetails?.orderRef}.`;
    url = window.encodeURI(url);
    window.open(url, "_blank");
  }

  var totalCost = 0;
  const getPackagingAmount = () => {
    let packagingFee = 0;
    orderDetails.bookedDishes.forEach(cart => {
      if (cart.isAddedOnDiscount) {
        packagingFee = packagingFee + cart.dish.packagingCost;
      }
    });
    return packagingFee;
  };
  const totalCostValue = orderDetails.bookedDishes.reduce((acc, item) => acc + item.dealPrice, 0);
  const packagingFee = getPackagingAmount()

  const getGSTAmount = () => {
    // Calculate 5% GST
    return (parseInt(totalCostValue) * GST_RATE) / 100;
  };

  const isOrderPreLunch  = orderDetails?.orderType === "pre-launch"
  return (
    <AppContainer>
      <OrderStatusBadge visible={false} orderType={orderDetails?.orderType} />
      <div className="order-status-screen-wrapper-container">
        <div className="order-status-screen-wrapper">
          <div className="order-status-topbar">
            <div className="order-status-kitchen-name">
              <span style={{ height: 24 }}>
                <button
                  onClick={onBackClick}
                  type="button"
                  className="btn back-btn"
                >
                  <BackBtnSvg fill="#fff" />
                </button>
              </span>
              {orderDetails?.orderType == "order-now" ? <span>Marol kitchen</span> : <span></span>}
              {orderDetails?.orderType == "order-now" ? <a className="order-status-kitchen-call" onClick={handleCallButtonClick} >
                <CallIcon />
              </a> : <span></span>}
            </div>
            <div className="order-status-main-header">{getOrderStatus()}</div>
            <div className="order-status-estimate-time-wrapper">
              <div className="order-status-estimate-time-label">{moment(orderDeliveredDate).isValid() ? `Order Delivered at ${moment(orderDeliveredDate).format("HH:MM A")}` :  `Estimate Delivery at ${deliveryTime}`}</div>
              {/* <button onClick={handleButtonClick} disabled={isLoading} className="order-status-refresh-btn">
                <span className={`order-status-refresh-icon ${isLoading && `order-status-refresh-icon-loading`}`}><RefreshIcon /></span>
              </button> */}
            </div>
          </div>

        </div>
        <div className="map-tracking-component">
          <MapTrackingComponent deliveryAddress={deliveryAddress} />
        </div>
        <div className="orderSummary_wrapper">
          {orderDetails?.orderType == "order-now" && (
            <>
              {/* <div style={{ margin: "0px 35px" }} className="order-info">
              <div className="order-info_left">
                <div className="delivery-info">
                  <div style={{height:21}} className="order-info_icon">
                    <AccessTimeOutlinedIcon />
                  </div>
                  <div className="order-info_time">
                    <span className="order-info_span">
                      {deliveryTime}
                    </span>
                  </div>
                </div>
              </div>
              <div style={{width:"auto"}} className="order-info_right">
                <div
                  className="delivery-info call-button"
                  onclick="window.open('tel:+918655861798');"
                >
                  <div style={{ display: "flex" }} className="order-info_icon">
                    <LocalPhoneIcon />
                  </div>
                  <div className="order-info_time">
                    <a href="tel:918655861798">
                      <span className="order-info_span">CALL NOW</span>
                    </a>
                  </div>
                </div>
              </div>
            </div> */}

              {driverNameReducer ? (
                <>
                  <div style={{ marginTop: 0 }} className="order-info delivery-partner-info-wrapper">
                    <div className="order-info_left">
                      <div className="delivery-info">
                        <span className="order-info_span">
                          <div className="delivery-partner-logo-wrapper">
                            <img width={42} height={42} src="https://cdn-icons-png.flaticon.com/512/4140/4140077.png" />
                            <div className="delivery-partner-name-wrapper">
                              <span className="delivery-partner-name">{driverNameReducer}</span>
                              <span className="delivery-partner-name-label">Your delivery partner</span>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div className="order-info_right">
                      {orderStatusReducer === "DELIVERED" ? <></> : <a style={{ border: "1px solid #670000" }} className="order-status-kitchen-call" onClick={handleCallDriverButtonClick} >
                        <CallIcon fill="#670000" />
                      </a>}
                      {/* <div
                      style={{width:"max-content"}}
                      className="delivery-info call-button"
                      onclick={`window.open('tel:+91${driverPhoneNumberReducer}');`}
                    >
                      <div
                        style={{ display: "flex" }}
                        className="order-info_icon"
                      >
                        <LocalPhoneIcon />
                      </div>
                      <div className="order-info_time">
                        <a href={`tel:+91${driverPhoneNumberReducer}`}>
                          <span className="order-info_span">CALL NOW</span>
                        </a>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ padding: 20 }} className="order-info_left delivery-partner-info-wrapper">
                    <span>Delivery agent will be assigned soon</span>
                  </div>
                </>
              )}
            </>
          )}
          <div className="ordered-dishes_container delivery-partner-info-wrapper">
            <h6 className="order-details">Order Details</h6>
            <section className="section-food-accordian">
              {orderDetails && orderDetails.bookedDishes && (
                <div className="food-accordian">
                  <div className="dish-wrapper-list">
                    {orderDetails.bookedDishes.map((bookedDish, i) => {
                      let dish = bookedDish.dish;
                      let isPriceBandVisible =
                        user.maxDishes == 2 && bookedDish.isAddedOnDiscount;
                      bookedDish.isAddedOnDiscount || orderDetails.preOrdered
                        ? (totalCost += bookedDish.dealPrice)
                        : (totalCost += bookedDish.price);
                      return (
                        <>
                          {dish.price && (
                            <Dish
                              key={dish._id}
                              id={dish._id}
                              name={dish.name}
                              portion={bookedDish?.portion}
                              category={"Starters"}
                              price={bookedDish.price}
                              type={dish.type ? dish.type.toUpperCase() : "VEG"}
                              img={
                                !orderDetails.preOrdered && dish.img //Temp change: pre-ordered dishes will not have images, change after 1st set of pre-launch users have booked
                                  ? S3_MENU_THUMBNAIL_FOLDER + dish.img
                                  : ""
                              }
                              mainImg={dish.img ? S3_MENU_FOLDER + dish.img : ""}
                              description={dish.description}
                              fromCart={false}
                              dish={dish}
                              customizeObj={bookedDish?.customizeObj}
                              calculateTotal={true}
                              isFromCart={false}
                              dealPrice={bookedDish.dealPrice}
                              parentComponent="dishBook"
                              isBooked={true}
                              isScheduleActive={false}
                              isPriceBandVisible={isPriceBandVisible}
                              isAddedOnDiscount={bookedDish.isAddedOnDiscount}
                              isFromOrderSummary={true}
                              from="orderSummary"
                            />
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              )}
            </section>
            {orderDetails?.instructionText && <div className="price-box price-box-bottom-line">
              <div className="cooking-instruction-icon-wrapper">
                <div className="cooking-instruction-icon">
                  <CookingIcon />
                </div>
                <div className="cooking-instruction-text">
                  <div className="">Cooking instruction</div>
                  <div className="cooking-instruction-text-content" >{orderDetails?.instructionText}</div>
                </div>
              </div>
            </div>}
          </div>
          <div className="delivery-address delivery-partner-info-wrapper">
            <div className="delivery-address_top">
              <span className="delivery-address_span">
                Delivery Address
                <span className="delivery-address_tag">
                  {deliveryAddress?.tag}
                </span>
              </span>
              <LocationOnIcon />
            </div>
            <p className="delivery-address_content">
              {deliveryAddress?.houseNumber}
              {deliveryAddress?.area && `, ${deliveryAddress?.area}`}
              {deliveryAddress?.landMark && `, ${deliveryAddress?.landMark}`}
              {deliveryAddress?.location?.locationName &&
                `, ${deliveryAddress?.location?.locationName}`}
            </p>
          </div>
          <div style={{ marginTop: 0 }} className="order-info delivery-partner-info-wrapper">
            <div className="order-info_left">
              <div className="delivery-info">
                <span className="order-info_span">
                  <div className="delivery-partner-logo-wrapper" onClick={onSupportClick} >
                    <img width={42} height={42} src={supportImg} />
                    <div className="delivery-partner-name-wrapper">
                      <span className="delivery-partner-name">Need help with your order?</span>
                      <span style={{ color: "#670000" }} className="delivery-partner-name-label">Get help & support</span>
                    </div>
                  </div>
                </span>
              </div>
            </div>
            <div className="order-info_right">

            </div>
          </div>
        </div>

        <div style={{ marginTop: 5 }} className="delivery-partner-info-wrapper">
          {orderDetails.paymentMode && (
            <div>
              <div className="billing-title billing-title-label">
                Payment Mode - {orderDetails.paymentMode}
              </div>
            </div>
          )}
        </div>

        <div className="billing-section ">
          <div style={{ marginTop: 5 }} className="delivery-partner-info-wrapper">
            <div className="billing-title billing-title-label">Billing Details</div>
            <div className="price-box price-box-wrapper">
              <div className="items-price items__first">
                <div className="price-title">Items Price</div>
                <div className="price-value">
                  {" "}
                  &#8377;
                  <>{isOrderPreLunch ? parseInt(orderDetails.totalCost || totalCostValue) :  parseInt(orderDetails.totalCost)}</>
                </div>
              </div>
              <div className="items-price items">
                <div className="price-title">Delivery Fees</div>
                <div className="price-value">
                  {" "}
                  &#8377; {orderDetails.deliveryFee}{" "}
                </div>
              </div>
              <div className="items-price items">
                <div className="price-title">Packaging</div>
                <div className="price-value">
                  {" "}
                  &#8377; <>{isOrderPreLunch ? orderDetails.packagingFee || packagingFee : orderDetails.packagingFee }</> {" "}
                </div>
              </div>
              <div className="items-price items">
                <div className="price-title">GST</div>
                <div className="price-value">
                  &#8377;
                  <>{isOrderPreLunch ? orderDetails.gstFee?.toFixed(2) ||  getGSTAmount()?.toFixed(2) : orderDetails.gstFee }</>
                </div>
              </div>
              <div className="items-price items__total">
                <div className="price-title">Total</div>
                <div className="price-value">
                  {" "}
                  &#8377;
                  {orderDetails.billingAmount}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomBar />
    </AppContainer>
  );
};

export default OrderSummary;
