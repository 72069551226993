import { useSelector } from "react-redux";
import AppContainer from "../util/AppContainer";
import "./locationSelector.css";
import GPSLocator from "./GPSLocator";
import RecentSearches from "./RecentSearches";
import LocationSearch from "./LocationSearch";
import { useState } from "react";
import { Spin } from "antd";

const LocationSelector = (props) => {
  const [loading, setLoading] = useState(false);
  const isAddAddressActive = useSelector(
    (state) => state.location.isAddAddressActive
  );

  return (
    <AppContainer>
      <div style={{ position: 'relative', height:"100%" }}>
        {loading && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1000,
            }}
          >
            <div style={{ color: '#fff' }}>
            <Spin />
            </div>
          </div>
        )}
        <LocationSearch from={props.from} />
        <GPSLocator from={props.from} setLoading={setLoading} />
        {!isAddAddressActive && <RecentSearches from={props.from} setLoading={setLoading} />}
      </div>
    </AppContainer>
  );
};

export default LocationSelector;
