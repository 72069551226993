import React, { useEffect, useState } from 'react';
import Sheet from 'react-modal-sheet';
import "./timeSlotBottonSlider.css"
import downArrow from "../../assets/downSort.svg"
import CloseSvg from '../../assets/ReactSvg/CloseSvg';
import { Select } from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { cartAction } from '../../store/cartSlice';
import timeSlots from '../ScheduleOrderButton/timeSlotsHelper';
import mixpanel from 'mixpanel-browser';
// import clevertap from 'clevertap-web-sdk';


const configs = [
    {
        stiffness: 300,
        damping: 30,
        mass: 0.2
    },
    {
        stiffness: 150,
        damping: 20,
        mass: 1
    }
];

const generateDateOptions = () => {
    const options = [];

    // Get the current date
    const currentDate = moment();

    // Generate options for the next 7 days
    for (let i = 0; i < 7; i++) {
        const date = currentDate.clone().add(i, 'days');
        const option = {
            value: date.format(), // Convert moment object to string
            label: date.isSame(currentDate) ? "Today" : date.format('Do MMM YYYY'), // Format the date label as needed
        };

        options.push(option);
    }

    return options;
};

const TimeSlotBottonSlider = ({ isOpen, setOpen, scheduleDate, setScheduleDate, chefsLatestTime }) => {
    const dateOptions = generateDateOptions();

    const [config, setConfig] = useState(configs[0]);
    const { date, time } = scheduleDate;
    const [selectedScheduleTime, setSelectedScheduleTime] = useState(scheduleDate);

    useEffect(() => {
        if (isOpen) {
            setSelectedScheduleTime(scheduleDate)
        }

    }, [isOpen])

    const dispatch = useDispatch();


    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Format the date as "MMM dd"
    const formattedDate = new Date(selectedScheduleTime?.date).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
    });

    const formattedTodayDate = today.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
    });


    const currentTime = moment().add(2, "hours");
    const orderDeliveryTime = chefsLatestTime || currentTime
    
    const isToday = formattedDate === formattedTodayDate;
    const filteredTimeSlots = timeSlots.filter((timeSlot) =>
        moment(timeSlot.value).isSameOrAfter(orderDeliveryTime, 'minute')
    );
    const filteredTimeSlotsArray = [{ label: "Order Now", value: "ORDER_NOW" }, ...filteredTimeSlots];
    const timeSlotsData = isToday ? filteredTimeSlotsArray : timeSlots;

    const handleDateChange = (date) => {
        const newDate = date === "Today" ? moment().format('Do MMM YYYY') : date
        const selectedDate = dateOptions.find((t) => moment(t.value).format('Do MMM YYYY') === newDate)?.value;
        if (selectedDate) {
            const time = selectedScheduleTime.time === "ORDER_NOW" ? "" : selectedScheduleTime.time
            const newScheduleDate = { time, date: moment(selectedDate) };
            setSelectedScheduleTime(newScheduleDate)
        }
    };
    const handleTimeChange = (time) => {
        const selectedTime = timeSlotsData.find((t) => t.label === time)?.value;
        const newScheduleDate = { ...selectedScheduleTime, time: selectedTime };
        setSelectedScheduleTime(newScheduleDate)
    };

    const selectedDate = moment.isMoment(selectedScheduleTime?.date) && dateOptions.find((d) => moment(d.value).format('Do MMM YYYY') === selectedScheduleTime?.date?.format("Do MMM YYYY"))?.label
    const selectedTime = timeSlotsData.find((d) => d.value === selectedScheduleTime?.time)?.label
    const dateOptionsValues = dateOptions.map((a) => ({ label: a.label, value: a.label }));

    useEffect(() => {
        if (timeSlotsData.length) {
            handleTimeChange(timeSlotsData[0].label)
        }
    }, [])

    return (
        <Sheet
            springConfig={config}
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            // snapPoints={[500, 0]}
            detent="content-height"
            onSnap={(snapIndex) =>
                console.log("> Current snap point index:", snapIndex)
            }
        >
            <Sheet.Container>
                <Sheet.Content>
                    <div className='deliveryTimeBoxWrapper'>
                        <div>
                            <h2 style={{ fontSize: 19 }} >Delivery timing</h2>
                            <span style={{ color: "#666666" }}>Select from available slots</span>
                        </div>
                        <div onClick={() => setOpen(false)}>
                            <CloseSvg />
                        </div>
                    </div>
                    <div className='deliveryTimeSlotSelector'>
                        <Select
                            onChange={handleDateChange}
                            className='deliveryTimeSlotSelect'
                            options={dateOptionsValues}
                            value={!!selectedDate ? selectedDate : undefined}
                            defaultValue={"Today"}
                            popupClassName="deliveryTimeSlotSelectPopup"
                            suffixIcon={<img height={14} width={14} src={downArrow} alt="down arrow" />}
                        />
                        <Select
                            onChange={handleTimeChange}
                            className='deliveryTimeSlotSelect'
                            options={timeSlotsData.map((a) => ({ label: a.label, value: a.label }))}
                            value={selectedTime}
                            popupClassName="deliveryTimeSlotSelectPopup"
                            suffixIcon={(<img height={14} width={14} src={downArrow}></img>)}
                        />
                    </div>
                    <button
                        onClick={() => {
                            if (selectedScheduleTime?.time === "ORDER_NOW") {
                                dispatch(cartAction.setIsScheduleNow(true));
                                dispatch(cartAction.setScheduleTime(null));
                                setScheduleDate({});
                                mixpanel.track("schedule date removed");
                                // clevertap.event.push("schedule date removed");
                                handleDateChange();
                                handleTimeChange();
                                setOpen(false);

                            } else if (selectedScheduleTime?.time && selectedScheduleTime?.date) {
                                dispatch(cartAction.setScheduleTime(selectedScheduleTime));
                                setScheduleDate(selectedScheduleTime);
                                mixpanel.track(`schedule time set ${selectedScheduleTime}`);
                                // clevertap.event.push(`schedule time set ${selectedScheduleTime}`);
                                dispatch(cartAction.setIsScheduleNow(false));
                                setOpen(false);
                            }
                        }}
                        disabled={!((selectedScheduleTime?.time && selectedScheduleTime?.date) || selectedScheduleTime?.time === "ORDER_NOW") || !selectedTime}
                        className="deliverySlotConfirmBtn"
                    >
                        CONFIRM SLOT
                    </button>

                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop onClick={() => setOpen(false)} />
        </Sheet>
    )
}

export default TimeSlotBottonSlider