import "./recent-searches.css";
import HistoryIcon from "@material-ui/icons/History";
import { useDispatch, useSelector } from "react-redux";
import { loginActions } from "../../store/loginSlice";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { FREE_DILIVERY_DISTANCE_RANGE, MINIMUM_AMOUNT_MAX_RANGE, MINIMUM_AMOUNT_MIN_RANGE, ORDER_DISTANCE_RANGE, ORDER_DISTANCE_RANGE_MIN } from "../../store/constants";
import { cartAction } from "../../store/cartSlice";
import { useRef } from "react";

const RecentSearches = (props) => {
  const { setLoading } = props
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const selectedAddress = useSelector((state) => state.auth.selectedAddress);
  const addresses = user.addressLine1;
  console.log("SELECTED_ADDRESS", selectedAddress);

  const sourceLocation = {
    lat: 19.116606,
    lng: 72.8854982,
  };

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
    if(props.from){
      dispatch(bottomBarActions.updateFrom({ from: "" }));
    }
  };

  const distanceMatrixRef = useRef(null);

  const onSelectAddressHandler = (data) => {
    setLoading(true)
    console.log("DATA :", data.location);

    distanceMatrixRef.current = new window.google.maps.DistanceMatrixService();
    distanceMatrixRef.current.getDistanceMatrix(
      {
        origins: [sourceLocation],
        destinations: [
          {
            lat: data?.location.lat,
            lng: data?.location.lng,
          },
        ],
        travelMode: "DRIVING",
      },
      (res, status) => {
        if (status !== "OK") {
          console.log("Unable to fetch...");
          setLoading(false)
        } else {
          let delivery_distance = res.rows[0].elements[0].distance.value;
          let delivery_duration = res.rows[0].elements[0].duration;
          let deliveryObject = {
            delivery_distance,
            delivery_duration,
            kitchenLocation: sourceLocation
          };
          dispatch(loginActions.setDeliveryDetails(deliveryObject));
          let deliverydeliveryDistanceInKMS = Math.round(
            delivery_distance / 1000
          );
          if (deliverydeliveryDistanceInKMS > ORDER_DISTANCE_RANGE) {
            dispatch(loginActions.setIsServiceable(false));
            dispatch(loginActions.setIsServiceableAlert(true));
            dispatch(loginActions.setUnserviceableModalOnCart(true));
            dispatch(loginActions.setMinimumAmount(0));
          } else if (deliverydeliveryDistanceInKMS > ORDER_DISTANCE_RANGE_MIN && 
            deliverydeliveryDistanceInKMS <= ORDER_DISTANCE_RANGE) {
            dispatch(loginActions.setIsServiceable(true));
            dispatch(loginActions.setIsServiceableAlert(false));
            dispatch(loginActions.setFarAwayModalOnCart(true));
            dispatch(loginActions.setMinimumAmount(MINIMUM_AMOUNT_MAX_RANGE));
          } else if (deliverydeliveryDistanceInKMS > FREE_DILIVERY_DISTANCE_RANGE && 
            deliverydeliveryDistanceInKMS < ORDER_DISTANCE_RANGE_MIN) {
            dispatch(loginActions.setIsServiceable(true));
            dispatch(loginActions.setIsServiceableAlert(false));
            dispatch(loginActions.setMinimumAmount(MINIMUM_AMOUNT_MIN_RANGE));
          } else {
            dispatch(loginActions.setIsServiceable(true));
            dispatch(loginActions.setIsServiceableAlert(false));
            dispatch(loginActions.setMinimumAmount(0));
          }

          let coords = { lat: data.location.lat, lng: data.location.lng };
          dispatch(loginActions.setSelectedAddress(data));
          dispatch(loginActions.setLocation(data.location.locationName));
          dispatch(cartAction.setIsAddressOptionsVisible(false));
          dispatch(loginActions.setCoords(coords));
          props.from ? switchTab(props.from) : switchTab("chef");
          setLoading(false)
        }
      }
    );
  };

  return (
    <>
      {addresses.length ? (
        <div className="recent-searches_wrapper">
          <h6>Saved address</h6>
          {addresses.length
            ? addresses.map((data) => {
                return (
                  <div
                    key={data._id}
                    className="recent-searches_container"
                    onClick={() => onSelectAddressHandler(data)}>
                    <HistoryIcon />
                    <div className="recent-address_container">
                      <h6>{data.tag}</h6>
                      <p>
                        {data.location.locationName.length >= 70
                          ? `${data.location.locationName.slice(0, 70)}...`
                          : data.location.locationName}
                      </p>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      ) : null}
    </>
  );
};

export default RecentSearches;
