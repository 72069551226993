import "./GPS-Locator.css";
import MyLocation from "@material-ui/icons/MyLocation";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { loginActions } from "../../store/loginSlice";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { locationActions } from "../../store/locationSlice";
import { FREE_DILIVERY_DISTANCE_RANGE, MINIMUM_AMOUNT_MAX_RANGE, MINIMUM_AMOUNT_MIN_RANGE, ORDER_DISTANCE_RANGE, ORDER_DISTANCE_RANGE_MIN } from "../../store/constants";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import Script from "react-load-script";
import { useRef } from "react";
// import clevertap from "clevertap-web-sdk";

const GPSLocator = (props) => {
  const { setLoading } = props
  const dispatch = useDispatch();
  const { isAddAddressActive } = useSelector((state) => state.location);

  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { lat: "", lng: "" },
  });

  const sourceLocation = {
    lat: 19.116606,
    lng: 72.8854982,
  };

  const showError = (error) => {
    toast.error(error, {
      position: toast.POSITION.BOTTOM_CENTER,
      toastId: "info1",
    });
  };

  const onSuccess = (location) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },
    });
    dispatch(
      loginActions.setCoords({
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      })
    );
    setLoading(false);
  };

  const setLocationError = (error) => {
    setLocation({
      loaded: false,
      error: {
        code: error.code,
        message: error.message,
      },
    });
  }

  const onError = (error) => {
    const urlParams = new URLSearchParams(window.location.search);

    // Get the value of the 'locationJson' parameter
    const locationJsonParam = urlParams.get('locationJson');

    if (locationJsonParam) {
      try {
        // Parse the parameter into a JSON object
        const locationData = JSON.parse(decodeURIComponent(locationJsonParam));
        onSuccess({ coords: { longitude: locationData?.lng, latitude: locationData?.lat } })
        console.log(locationData);
      } catch (error) {
        setLocationError(error)
        console.error('Error parsing JSON:', error);
      }finally{
        setLoading(false);
      }
    } else {
      setLocationError(error)
      setLoading(false);
    }
    setLoading(false);
  };

  const onGeoLocationClickHandler = () => {
    mixpanel.track("geolocation-clicked");
    // clevertap.event.push("geolocation-clicked");
    setLoading(true)
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError, {
        enableHighAccuracy: true
      });
    } else {
      setLoading(false);
      showError("Geolocation not supported");
    }
  };

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
    if (props.from) {
      dispatch(bottomBarActions.updateFrom({ from: "" }));
    }
  };

  const distanceMatrixRef = useRef(null);

  const GPSHandler = async () => {
    if (location.loaded == true) {
      distanceMatrixRef.current =
        new window.google.maps.DistanceMatrixService();
      distanceMatrixRef.current.getDistanceMatrix(
        {
          origins: [sourceLocation],
          destinations: [
            {
              lat: location.coordinates.lat,
              lng: location.coordinates.lng,
            },
          ],
          travelMode: "DRIVING",
        },
        (res, status) => {
          if (status !== "OK") {
            console.log("Unable to fetch...");
          } else {
            let delivery_distance = res.rows[0].elements[0].distance.value;
            let delivery_duration = res.rows[0].elements[0].duration;
            let delivery_destination = res.destinationAddresses[0];
            let deliveryObject = {
              delivery_distance,
              delivery_duration,
              kitchenLocation: sourceLocation
            };

            dispatch(loginActions.setDeliveryDetails(deliveryObject));
            let deliverydeliveryDistanceInKMS = Math.round(
              delivery_distance / 1000
            );
            if (deliverydeliveryDistanceInKMS > ORDER_DISTANCE_RANGE) {
              dispatch(loginActions.setIsServiceable(false));
              dispatch(loginActions.setSelectedAddress(null));
              dispatch(loginActions.setIsServiceableAlert(true));
              dispatch(loginActions.setUnserviceableModalOnCart(true));
              dispatch(loginActions.setMinimumAmount(0));
            } else if (deliverydeliveryDistanceInKMS > ORDER_DISTANCE_RANGE_MIN &&
              deliverydeliveryDistanceInKMS <= ORDER_DISTANCE_RANGE) {
              dispatch(loginActions.setIsServiceable(true));
              dispatch(loginActions.setSelectedAddress(null));
              dispatch(loginActions.setIsServiceableAlert(false));
              dispatch(loginActions.setFarAwayModalOnCart(true));
              dispatch(loginActions.setMinimumAmount(MINIMUM_AMOUNT_MAX_RANGE));
            } else if (deliverydeliveryDistanceInKMS > FREE_DILIVERY_DISTANCE_RANGE &&
              deliverydeliveryDistanceInKMS < ORDER_DISTANCE_RANGE_MIN) {
              dispatch(loginActions.setIsServiceable(true));
              dispatch(loginActions.setSelectedAddress(null));
              dispatch(loginActions.setIsServiceableAlert(false));
              dispatch(loginActions.setMinimumAmount(MINIMUM_AMOUNT_MIN_RANGE));
            } else {
              dispatch(loginActions.setIsServiceable(true));
              dispatch(loginActions.setSelectedAddress(null));
              dispatch(loginActions.setIsServiceableAlert(false));
              dispatch(loginActions.setMinimumAmount(0));
            }

            dispatch(loginActions.setLocation(delivery_destination));
            props.from ? switchTab(props.from) : isAddAddressActive ? switchTab("addressForm") : switchTab("chef");
            dispatch(locationActions.setIsAddAddressActive(false));
          }
        }
      );
    } else {
      if (location?.error?.message) {
        showError("Need location permission.");
      }
    }
  };

  useEffect(() => {
    GPSHandler();
  }, [location]);

  return (
    <>
      <Script
        url="https://maps.googleapis.com/maps/api/js?key=AIzaSyDSrNgyDHSkqDzUMNIJEbsLt3u_q1RbjYw&libraries=places"
        onLoad={GPSHandler}
      />
      <div
        className="location-search_input gps_wrapper"
        onClick={() => onGeoLocationClickHandler()}>
        <MyLocation />
        <div className="gps_content">
          <h6>Use current location</h6>
          <p>Using GPS</p>
        </div>
      </div>
      <ToastContainer limit={1} />
    </>
  );
};

export default GPSLocator;
