import React, { Suspense } from "react";
import Banner from "./Banner";
import BannerWrapper from "./BannerWrapper";
import Slider from "react-slick";
import mixpanel from 'mixpanel-browser';
// import clevertap from "clevertap-web-sdk";

const Banners = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      if (current - next < 0) {
        mixpanel.track("banner-slide-l2r");
        // clevertap.event.push("banner-slide-l2r");
      } else {
        mixpanel.track("banner-slide-r2l");
        // clevertap.event.push("banner-slide-l2r");
      }
    },
  };
  return (
    <BannerWrapper>
      <Slider {...settings}>
        <Banner src="images/banner-intro.jpg" />
        <Banner src="images/banner-kitchen.jpg" />
        <Banner src="images/banner-order.jpg" />
        <Banner src="images/banner-fcbb.jpg" />
      </Slider>
    </BannerWrapper>
  );
};

export default Banners;
