import React from 'react';
import Sheet from 'react-modal-sheet';
import "./customizeBottomSlider.css"
import CloseCrossSvg from '../../assets/ReactSvg/CloseCrossSvg';


const VegNonVegLogo = ({ type, size, hideText }) => {
    return <span className='vegnonVegLogo'>
        {type?.toLowerCase() === "VEG".toLowerCase() && (
            <span className="food-list-item veg saff">
                <figure className='vegnonvegFigure'>
                    <img style={size ? { height: size } : {}} src="images/icon-veg.svg" alt="veg" loading="lazy" />
                    {!hideText && <span style={{ textTransform: "uppercase", marginTop: 3 }}>{type}</span>}
                </figure>
            </span>
        )}
        {type?.toLowerCase() === "EGG".toLowerCase() && (
            <span className="food-list-item egg saff">
                <figure className='vegnonvegFigure'>
                    <img style={size ? { height: size } : {}} src="images/icon-egg.svg" alt="egg" loading="lazy" />
                    {!hideText && <span style={{ textTransform: "uppercase" }}>{type}</span>}
                </figure>
            </span>
        )}
        {type?.toLowerCase() === "NON VEG".toLowerCase() && (
            <span className="food-list-item non-veg saff">
                <figure className='vegnonvegFigure'>
                    <img
                        style={size ? { height: size } : {}}
                        src="images/icon-non-veg.svg"
                        alt="non-veg"
                        loading="lazy"
                    />
                    {!hideText && <span style={{ textTransform: "uppercase" }}>{type}</span>}
                </figure>
            </span>
        )}
    </span>
}

const transformData = (inputData) => {
    return inputData.map(item => {
        let newItem = {
            label: item.label,
            isOptional: item.isOptional
        };
        if (item.options && item.options.length > 0) {
            let selectedOption = item.options.find(option => option.isSelected);
            if (selectedOption) {
                newItem.text = selectedOption.text;
                if (selectedOption.extraPrice) {
                    newItem.extraPrice = selectedOption.extraPrice;
                } else {
                    newItem.extraPrice = 0;
                }
            }
        }
        return newItem;
    });
}

const RemoveDishPortion = ({ isOpen, setOpen, dishPortionDetails = [], dishInfo, onAddDish, setShowAdd, setShowAdded, onRemoveDish, totalCartAddedDishCount }) => {
    
    const handleAddPortionClick = (dish) => {
        const extraPrice = dish?.customizeObj?.reduce((sum, item) => {
            if (item.hasOwnProperty('extraPrice')) {
                return sum + item.extraPrice;
            }
            return sum;
        }, 0);
        const customizeObj = dish?.customizeObj.map((custom) => {
            const { _id, ...rest } = custom;
            return {...rest}
        })
        onAddDish(dishInfo._id, extraPrice, customizeObj, dish.cartId)
    }

    const onRemoveDishClick = (dish) =>{
        const isMultiplePortionAdded = totalCartAddedDishCount > 1;
        onRemoveDish(
            dish._id,
            setShowAdd,
            setShowAdded,
            dish.portion,
            dish?.cartId,
            isMultiplePortionAdded
        )
    }


    return (
        <Sheet
            springConfig={{
                stiffness: 300,
                damping: 30,
                mass: 0.2
            }}
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            detent="content-height"
            onSnap={(snapIndex) =>
                console.log("> Current snap point index:", snapIndex)
            }
        >
            <Sheet.Container>
                <Sheet.Content>
                    <div className='closeButtonWrapper'>
                        <div onClick={() => setOpen(false)} className='closeButtonBox'>
                            <CloseCrossSvg />
                        </div>
                    </div>
                    <div style={{ padding: "0px 30px 0px 30px", backgroundColor: "#FFFFFF", borderRadius: "50px 50px 0px 0px" }}>
                        <div style={{ borderBottom: "1px dotted grey" }} className='dishInfoPortionSelector'>
                            <div><VegNonVegLogo type={dishInfo?.type} /></div>
                            <div style={{ borderBottom: "0px" }} className='customizeLabel'><h6 className='dishInfoPortionSelectorHeader'>Customize your portion</h6>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", rowGap: 20 }} className='customizeDescContainer'>
                            {dishPortionDetails.map((dish) => {
                                const quantityTextLabel = dish?.customizeObj?.length ? dish?.customizeObj.map(item => item.text).join(' ').trim() : "";
                                return <div className='customizeBoxLabel'>
                                    <div className='customizeDishInfoLabel'>
                                        <VegNonVegLogo type="veg" size={13} hideText={true} />
                                        <div>
                                            <h6 style={{ fontSize: 14 }} className='dishInfoPortionSelectorHeader'>{dish.name}</h6>
                                            <div style={{ fontSize: 13, marginTop: 4 }}>₹{dish.price}</div>
                                            <div style={{ fontSize: 13, marginTop: 2, color: "grey" }}>{quantityTextLabel}</div>
                                        </div>
                                    </div>
                                    <div className='customizeDishInfoPortion'>
                                        <div className="input-group-customize-add-items">
                                            <button
                                                className="btn-customizebtn-minus-item"
                                            onClick={()=>onRemoveDishClick(dish)}
                                            >
                                                <img
                                                    src="images/icon-minus.svg"
                                                    alt="search-item"
                                                    loading="lazy"
                                                />
                                            </button>
                                            <div className='customizebtn-item-center-label'>{dish.portion}</div>
                                            <button
                                                className="btn-customizebtnbtn-add-item"
                                                onClick={() => handleAddPortionClick(dish)}
                                            >
                                                <img
                                                    src="images/icon-plus.svg"
                                                    alt="search-item"
                                                    loading="lazy"
                                                />
                                            </button>
                                        </div>
                                        <div style={{ fontSize: 13, marginTop: 4, display: "flex", justifyContent: "flex-end" }}>₹{dish.dealPrice}</div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop onClick={() => setOpen(false)} />
        </Sheet>
    )
}

export default RemoveDishPortion
