import React, { useState, useEffect, useRef, Suspense } from "react";
import ChefCard from "./ChefCard";
import Slider from "react-slick";
import { useSwipeable } from "react-swipeable";
import { useLongPress } from "use-long-press";
import ChefCardLoader from "./ChefCardLoader";
import Instruction from "../util/Instruction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import mixpanel from 'mixpanel-browser';
import { current } from "immer";
import { useDispatch, useSelector } from "react-redux";
import { chefActions } from "../../store/chefSlice";
// import clevertap from "clevertap-web-sdk";
//toast.configure();

const ChefCardsSlider = (props) => {
  const dispatch = useDispatch();
  const exploreChefClickedId = useSelector((state) => state.chef.exploreChefClickedId);
  
  let longPress = false;
  let scrollerClicked = false;
  let [showInstruction, setShowInstruction] = useState(false);
  let cuiratoInstructionDismissed =
    localStorage.getItem("cuirato-instruction-dismissed") === "true";
  let [lastSlide, setLastSlide] = useState(false);
  const bind = useLongPress(
    () => {
      if (!scrollerClicked) {
        document.body.classList.add("prevent-scroll");
        document
          .getElementsByClassName("slick-current")[1]
          .classList.add("pressed");
        longPress = true;
        console.log("use-long-press: Long pressed!");
      }
    },
    {
      onStart: (event) => {
        console.log("Press started");
      },
      onFinish: (event) => {
        if (!scrollerClicked) {
          document.body.classList.remove("prevent-scroll");
          document
            .getElementsByClassName("slick-current")[1]
            .classList.remove("pressed");
          longPress = false;
          console.log("Long press finished");
        }
      },
      threshold: 400,
    }
  );

  const scrollerBind = useLongPress(() => {}, {
    onStart: (event) => {
      scrollerClicked = true;
      console.log("scoller pressed");
      mixpanel.track("chef-info-scroller-clicked");
      // clevertap.event.push("chef-info-scroller-clicked");
    },
    onCancel: (event) => {
      scrollerClicked = false;
      console.log("scoller released");
    },
    onFinish: (event) => {
      scrollerClicked = false;
      console.log("scoller released");
    },
  });

  const menuScrollerBind = useLongPress(() => {}, {
    onStart: (event) => {
      scrollerClicked = true;
      console.log("scoller pressed");
      mixpanel.track("menu-stories-scroller-clicked");
      // clevertap.event.push("menu-stories-scroller-clicked");
    },
    onCancel: (event) => {
      scrollerClicked = false;
      console.log("scoller released");
    },
    onFinish: (event) => {
      scrollerClicked = false;
      console.log("scoller released");
    },
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (!scrollerClicked) {
        slideRef.current.slickNext();
      }
    },
    onSwipedRight: () => {
      if (!scrollerClicked) {
        slideRef.current.slickPrev();
      }
    },
    preventDefaultTouchmoveEvent: true,
    // trackMouse: true,
    delta: 50, //control swipe length
  });

  const setInstructuionTimer = () => {
    if (!cuiratoInstructionDismissed) {
      setTimeout(function () {
        if (!cuiratoInstructionDismissed) setShowInstruction(true);
      }, 5000);
    }
  };

  const isMobileDevice = () => {
    /* Storing user's device details in a variable*/
    let details = navigator.userAgent;

    /* Creating a regular expression 
    containing some mobile devices keywords 
    to search it in details string*/
    let regexp = /android|iphone|kindle|ipad/i;

    /* Using test() method to search regexp in details
    it returns boolean value*/
    let isMobileDevice = regexp.test(details);
    return isMobileDevice;
  };

  const slideRef = useRef();

  const settings = {
    centerMode: true,
    centerPadding: "11%",
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    mobileFirst: true,
    pauseOnHover: true,
    draggable: true,
    swipe: true,
    swipeToSlide: true,
    touchMove: false,
    draggable: true,
    accessibility: false,
    arrows: false,
    adaptiveHeight: false,
    variableWidht: false,
    infinite: false,
    useTransform: true,
    trasition: "ease-in-out",
    beforeChange: (current, next) => {
      if (current == next){
        toast.dismiss();
        toast.error("You've reached the end of the list.", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "info1",
        });
      }  
      if (current - next < 0) {
        mixpanel.track("chef-swipe-l2r");
        // clevertap.event.push("chef-swipe-l2r");
      } else {
        mixpanel.track("chef-swipe-r2l");
        // clevertap.event.push("chef-swipe-r2l");
      }
    },
  };

  const onDismiss = () => {
    setShowInstruction(false);
    cuiratoInstructionDismissed = false;
    localStorage.setItem("cuirato-instruction-dismissed", true);
  };

  const autoSwipe = () => {
    //setShowInstruction(true);
    // document
    //   .getElementsByClassName("slick-current")[1]
    //   .classList.add("pressed");
      // setTimeout(() => {
        slideRef.current.slickNext();
        //setShowInstruction(false);
      // }, 3000);
  };

  const autoReverseSwipe = () => {
    // document
    //   .getElementsByClassName("slick-current")[1]
    //   .classList.add("pressed");
      slideRef.current.slickPrev();
  };  

  useEffect(() => {
    //setInstructuionTimer();

    const noContext = document.getElementsByClassName("swipehere");

    noContext[0].addEventListener(
      "contextmenu",
      function (e) {
        e.preventDefault();
      },
      false
    );
  }, []);

  useEffect(() => {
    if(props.autoSwipe === 0){
      autoSwipe();
    } else if(props.autoSwipe === 1){
      autoReverseSwipe();
    }
  }, [props.autoSwipe]);
  
  const chefListArray = [...props.chefList];

  chefListArray.sort((a, b) => (a?.online === b?.online ? 0 : a?.online ? -1 : 1));
  

  useEffect(() =>{
    if(exploreChefClickedId && slideRef.current){
      const selectedChef = chefListArray.findIndex((c) => c._id === exploreChefClickedId);
      if(selectedChef !== -1){
        if(selectedChef !== slideRef?.current?.innerSlider?.state?.currentSlide){
          slideRef.current.slickGoTo(selectedChef);
        }
        dispatch(chefActions.onExploreChefClick(""));
      }else{
        dispatch(chefActions.onExploreChefClick(""));
      }
    }else{
      dispatch(chefActions.onExploreChefClick(""));
    }
  },[exploreChefClickedId, slideRef])

  return (
    <div className="swipehere" {...handlers} >
      {isMobileDevice() && showInstruction && (
        <Instruction onDismiss={onDismiss} />
      )}
      <Slider {...settings} ref={slideRef}>
        {props.chefListLoaded ? (
          chefListArray.map((chef, index) => (
            <Suspense key={index} fallback={<ChefCardLoader />}>
              <ChefCard
                id={"s" + index}
                details={chef}
                scrollerBind={scrollerBind}
                menuScrollerBind={menuScrollerBind}
              />
            </Suspense>
          ))
        ) : (
          <ChefCardLoader />
        )}
      </Slider>
      <ToastContainer limit={1} />
    </div>
  );
};

export default ChefCardsSlider;
