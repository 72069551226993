import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuTopBar from "../menu/MenuTopBar";
import BottomBar from "../BottomBar";
import MenuSearch from "../menu/MenuSearch";
import MenuTypeFilter from "../menu/MenuTypeFilter";
import AppContainer from "../util/AppContainer";
import { fetchDishes } from "../../store/menuActions";
import DishMenuCategories from "../menu/DishMenuCategories";
import "./dishes.css";
import ModelSaff from "./ModelStaff";
import ModelCuisines from "./ModelCuisines";
import { updateDishCheckedInUser } from "../../store/loginActions";
import ReactGA from "react-ga4";
import { bottomBarActions } from "../../store/bottomBarSlice";
import MenuCategories from "../menu/MenuCategories";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import mixpanel from "mixpanel-browser";
// import clevertap from "clevertap-web-sdk";

const Dishes = (props) => {
  const isKeyboardOpen = useDetectKeyboardOpen();
  useEffect(() => {
    //ReactGA.send({ hitType: "pageview", page: "/dishes" });
    ReactGA.event({
      category: "tab",
      action: "dishes",
    });
  }, []);
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const freeMenuidsOpened = useSelector(
    (state) => state.auth.freeMenuidsOpened
  );
  const allCategories = useSelector((state) => state.chef.categories);
  const dishes = useSelector((state) => state.menu.dishes);
  const numberOfMenus = useSelector((state) => state.menu.numberOfMenus);
  const noDishes = useSelector((state) => state.menu.noDishes);
  const dishesLoaded = useSelector((state) => state.menu.dishesLoaded);
  const tilesClicked = useSelector((state) => state.dish.tilesClicked);
  const activeTile = useSelector((state) => state.dish.activeTile);
  const [modalShow, setModalShow] = useState();
  const [browseValue, setBrowseValue] = useState({ model: false, value: "" });
  const allDishCategories = [];
  dishes.forEach((dish) => {
    dish.categories.forEach((category) => {
      allDishCategories.push(category);
    });
  });
  const askForSignupToCheckPrice = !user.referral;
  const categories = dishesLoaded ? allDishCategories : []; //dishes?.map(dish => dish.categories);
  const [categoriesdata, setCategoriesdata] = useState(categories);
  const [showAddedItemPopup, setShowAddedItemPopup] = useState(false);
  const [showDishPriceValidation, setShowDishPriceValidation] = useState(false);
  const { totalCost } = useSelector(({ cart }) => cart);
  const [allTiles, setAllTiles] = useState([]);
  const [allTilesData, setAllTilesData] = useState([]);
  const [currentCategoriesData, setCurrentCategoriesData] = useState([]);
  const [menuIdsData, setMenuIdsData] = useState([]);

  const keys = Object.keys(allCategories);

  useEffect(() => {
    keys.forEach((key) => {
      let menuCategories = allCategories[key];
      menuCategories.forEach((category) => {
        //category.menuId = key;
        let processed = false;
        allTiles.forEach((tile) => {
          if (tile.name === category.name) {
            tile.categories.push(category);
            tile.menuIds.push(allCategories[key]["menuId"]);
            processed = true;
            return;
          }
        });
        if (!processed) {
          let newTile = {
            name: category.name,
            img: category.img,
            categories: [],
            menuIds: [],
          };
          newTile.categories.push(category);
          newTile.menuIds.push(allCategories[key]["menuId"]);
          allTiles.push(newTile);
        }
      });
    });
    setAllTiles(allTiles);
    setAllTilesData(allTiles);
  }, [allCategories]);

  // console.log(dishes);
  // console.log("tilesClicked", tilesClicked);
  // console.log("activeTile", filteredCategories);
  // console.log(
  //   "allTiles",
  //   allTiles.filter((tile) => tile.name === "Soups")
  // );
  const handleBrowse = () => {
    setModalShow(true);
  };

  useEffect(() => {
    if (totalCost !== 0) {
      setShowAddedItemPopup(true);
    } else {
      setShowAddedItemPopup(false);
    }
  }, [totalCost]);

  const showSignUp = () => {
    dispatch(bottomBarActions.loadTab({ tabName: "account" }));
    dispatch(bottomBarActions.updateFrom({ from: "cart" }));
    mixpanel.track("show-signup-clicked");
    // clevertap.event.push("show-signup-clicked");
  };

  const validateDishPriceCheck = () => {
    if (askForSignupToCheckPrice) {
      setShowDishPriceValidation(true);
      return false;
    } else {
      setShowDishPriceValidation(false);
      return true;
    }
  };

  const onCheckPrice = async (dishID, setShowAdd) => {
    if (validateDishPriceCheck()) {
      dispatch(updateDishCheckedInUser(user, dishID));
      setShowAdd(true);
    }
    //call pricing api to get current price
  };

  useEffect(() => {
    if (categories) {
      setCategoriesdata(categories);
    }
  }, [dishesLoaded, numberOfMenus]);

  // const menuIds = user.phonenumber ? user.menuIdsOpened : freeMenuidsOpened;

  // useEffect(() => {
  //   dispatch(fetchDishes(menuIds));
  // }, [dispatch, menuIds]);

  // const { categories: filteredCategories, menuIds: filteredMenuIds } =
  //   allTiles?.filter((tile) => tile.name == "Soups");

  useEffect(() => {
    tilesClicked &&
      setCurrentCategoriesData(
        allTiles.filter((tile) => tile.name == activeTile)[0].categories
      );
    tilesClicked &&
      setMenuIdsData(
        allTiles.filter((tile) => tile.name == activeTile)[0].menuIds
      );
  }, [tilesClicked, allTiles, activeTile]);

  const showCart = () => {
    dispatch(bottomBarActions.loadTab({ tabName: "cart" }));
  };

  return (
    <AppContainer id={props.id}>
      <MenuTopBar
        name="Dishes from all Chefs"
        cuisine="Find your favourite dishes here"
        notMenu={true}
        tilesClicked={tilesClicked}
      />
      <div className="page-inner">
        {tilesClicked && allTiles.length > 0 ? (
          <>
            <MenuSearch
              categories={
                allTiles.filter((tile) => tile.name == activeTile)[0].categories
              }
              setCategoriesdata={setCurrentCategoriesData}
              menuIds={
                allTiles.filter((tile) => tile.name == activeTile)[0].menuIds
              }
              setMenuIdsData={setMenuIdsData}
            />
            <MenuTypeFilter
              allCategorie={
                allTiles.filter((tile) => tile.name == activeTile)[0].categories
              }
              categories={currentCategoriesData}
              setCategoriesdata={setCurrentCategoriesData}
            />
            <MenuCategories
              categories={currentCategoriesData}
              menuIds={menuIdsData}
              saff={false}
              onCheckPrice={onCheckPrice}
              setShowAddedItemPopup={setShowAddedItemPopup}
              parentComponent="dishes"
            />
          </>
        ) : (
          <>
            <MenuSearch
              categories={allTiles}
              setCategoriesdata={setAllTilesData}
            />
            <DishMenuCategories
              categories={allTilesData}
              allCategories={allCategories}
              saff={true}
              noDishes={noDishes}
              onCheckPrice={onCheckPrice}
              setShowAddedItemPopup={setShowAddedItemPopup}
              parentComponent="dishes"
            />
          </>
        )}
      </div>
      {!isKeyboardOpen && <BottomBar />}
      <ModelSaff
        categories={categoriesdata}
        show={modalShow}
        setBrowseValue={setBrowseValue}
        setModalShow={setModalShow}
      />
      <ModelCuisines
        browseValue={browseValue}
        setBrowseValue={setBrowseValue}
      />
      {!isKeyboardOpen && (
        <div
          className={
            showDishPriceValidation
              ? "browser-snackbar active"
              : "browser-snackbar deactive"
          }
          id="cart_option"
        >
          <div
            onClick={showSignUp}
            className="browser-snackbar-detail"
          >
            <ul className="footer-list">
              <li className="list-item-options">
                <a href="#" className="menu-link">
                <span>Login</span>
                </a>
              </li>
              <li className="list-item-description">
                  <div className="total-items">
                    <a href="#" className="cart-items">
                      <span>Login to check current price of dishes</span>
                    </a>
                  </div>
                </li>
            </ul>
          </div>
        </div>
      )}
      {!isKeyboardOpen && <ToastContainer limit={1} />}
    </AppContainer>
  );
};

export default Dishes;
