import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chefActions } from "../../store/chefSlice";
import { S3_MENU_FOLDER } from "../../store/constants";
import { fetchDishBand } from "../../store/menuActions";
import "./exploreStory.css";
import { toast } from "react-toastify";
import { menuActions } from "../../store/menuSlice";
import { bottomBarActions } from "../../store/bottomBarSlice";
import mixpanel from "mixpanel-browser";
import { getDisplayChefTimeLabel, getTodayDateValue } from "../../utils/timeHelper";
import moment from "moment";
import ReactPixel from "react-facebook-pixel";
import ImageLoader from "../chef/ImageLoader";
import { addDish, removeDish, updatePortion } from "../../store/cartActions";
// import clevertap from "clevertap-web-sdk";
import { calculateCurrentPrice, combineTheDate } from "../../utils/helper";
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactPlayer from "react-player";
import AudioSvg from "../../assets/ReactSvg/AudioSvg";
import AudioMuteSvg from "../../assets/ReactSvg/AudioMuteSvg";

const VideoPlayer = memo(({ videoUrl, loaded, onReady, isPlaying: isPlayingVideo, seekTimeRef, seekTime, setSeekTime }) => {
  const storyPlayer = useRef(null);
  const [isPlaying, setPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    setPlaying(isPlayingVideo);
    if (isPlayingVideo) {
      storyPlayer.current.seekTo(seekTimeRef.current, 'seconds');
    }
  }, [isPlayingVideo]);

  useEffect(() => {
    if (!loaded) {
      onReady();
    }
  }, [loaded, onReady]);

  const onProgress = (props) => {
    if (props.playedSeconds === 0 && seekTimeRef.current > 0) {
      seekTimeRef.current = seekTimeRef.current
    } else {
      seekTimeRef.current = props.playedSeconds
    }
  }

  const onMuteClick = () => {
    setIsMuted((prev) => !prev)
  }

  return (
    <div className='player-wrapper'>
      <button className="react-player-mute-button" onClick={onMuteClick} style={{ background: "transparent", border: "0px" }}>{!isMuted ? <AudioMuteSvg /> : <AudioSvg />}</button>
      <ReactPlayer
        ref={storyPlayer}
        className='react-player'
        url={videoUrl}
        loop={true}
        muted={isMuted}
        playing={isPlaying}
        onProgress={onProgress}
        width='100%'
        height='100%'
        onPlay={onReady}
      />
    </div>
  );
});

const ExploreStory = memo((props) => {
  const { categoryId, name, headers, dishSpecialType, dishInfo, chefName, chefId, action, isPaused, storyImages, labels, labelDesc } = props;
  const chefTimeData = useSelector((state) => state.chef.chefTime);
  const currentChefsTime = chefTimeData[chefId]
  const { cartData, scheduleTime, isScheduleNow } = useSelector(({ cart }) => cart);
  const scheduleDateTime = combineTheDate(scheduleTime?.date, scheduleTime?.time);
  const currentDishCount = cartData?.find((order) => order._id === dishInfo._id)?.portion || 0;
  const menuCartDataInfo = cartData.find((c) => c.dishId === dishInfo._id);
  const user = useSelector(({ auth }) => auth.user);
  const loggedIn = user.phonenumber ? true : false;
  const isServiceable = useSelector((state) => state.auth.isServiceable);
  const chefList = useSelector((state) => state.chef.chefList);
  const currentChefDetails = chefList.find((c) => c._id === chefId) || {};
  const isDishAddedToCart = cartData.map((cart) => cart._id)?.includes(dishInfo._id);
  const discountType = loggedIn
    ? user.maxDishes > user.totalDishesBooked
      ? "deepDiscount"
      : "normal"
    : "deepDiscount";

  const dispatch = useDispatch();

  const [currentPrice, setCurrentPrice] = useState(0);
  const currentPriceRef = useRef()
  const seekTimeRef = useRef(0)
  const [loaded, setLoaded] = useState(false);
  const [seekTime, setSeekTime] = useState(0);
  const handleMenuClick = () => {
    dispatch(chefActions.onMenuCategorieId(categoryId));
    dispatch(bottomBarActions.updateFrom({ from: "explore" }));
    dispatch(menuActions.setIsMenuClicked(true));
    dispatch(bottomBarActions.loadTab({ tabName: "menu" }));
    dispatch(chefActions.onMenuClick(props.chefId));
    mixpanel.track("go-to-menu-clicked explore");
    // clevertap.event.push("go-to-menu-clicked explore");
  };

  const addToCard = (dishId, extraPrice = 0, customizeObj = []) => {
    mixpanel.track(`explore add to cart clicked`);
    // clevertap.event.push(`explore add to cart clicked`);
    const isDishAdded = cartData.find((cart) => cart._id === dishId);
    if (isDishAdded && isDishAdded.isAddedOnDiscount) {
      toast.error("You can't add more portions for the discount price.");
      return;
    }
    if (!user.userid) {
      dispatch(bottomBarActions.loadTab({ tabName: "account" }));
    } else {
      let totalDiscountedDishesAdded = 0;
      cartData.forEach((cart) => {
        if (cart.isAddedOnDiscount == true) {
          totalDiscountedDishesAdded += 1;
        }
      });

      let isDiscountAllowed =
        user.totalDishesBooked + totalDiscountedDishesAdded < user.maxDishes;

      let isAddedOnDiscount =
        isDiscountAllowed && user.maxDishes !== 0 ? true : false;
      const payload = {
        cartPayload: {
          userId: user.userid,
          dishId: dishId,
          dealPrice: currentPrice + extraPrice,
          customizeObj: customizeObj,
          isAddedOnDiscount: isAddedOnDiscount,
          price: dishInfo.price,
          portion: currentDishCount + 1,
        },
        dish: { ...dishInfo, portion: currentDishCount + 1 },
        maxDishes: user.maxDishes,
        isFromCart: false,
        actionType: "ADD"
      };
      if (payload?.cartPayload?.portion < 1) {
        toast.error("Please add portion");
        return;
      }
      if (currentDishCount === 0) {
        dispatch(addDish(payload));
      } else {
        const cartDishInfo = cartData?.find((cart) => cart._id === dishId);
        payload.cartId = cartDishInfo.cartId;
        payload.cartPayload.dealPrice = menuCartDataInfo.dealPrice;
        dispatch(updatePortion(payload));
      }

      if (props.setShowAddedItemPopup) {
        props.setShowAddedItemPopup(true);
      }
    }
    mixpanel.track("added-to-cart");
    // clevertap.event.push("added-to-cart");
    ReactPixel.track("AddToCart");
  };

  const onRemoveDish = async (
    dishId,
  ) => {
    const payload = {
      userId: user.userid,
      dishId: dishId,
      isActive: false,
      maxDishes: user.maxDishes,
      dealPrice: menuCartDataInfo.dealPrice,
      isFromCart: false,
    };
    let totalDiscountedDishesAdded = 0;
    let isDiscountAllowed =
      user.totalDishesBooked + totalDiscountedDishesAdded < user.maxDishes;
    let isAddedOnDiscount =
      isDiscountAllowed && user.maxDishes !== 0 ? true : false;

    if (currentDishCount > 1) {
      const payload = {
        cartPayload: {
          userId: user.userid,
          dishId: dishId,
          dealPrice: currentPrice,
          isAddedOnDiscount: isAddedOnDiscount,
          price: dishInfo.price,
          portion: currentDishCount - 1,
        },
        dish: { ...dishInfo, portion: currentDishCount - 1 },
        maxDishes: user.maxDishes,
        isFromCart: false,
        actionType: "REMOVE"
      };
      const cartDishInfo = cartData?.find((cart) => cart._id === dishId);
      payload.cartId = cartDishInfo.cartId;
      payload.cartPayload.dealPrice = menuCartDataInfo.dealPrice;
      dispatch(updatePortion(payload));
    } else {
      payload.dealPrice = menuCartDataInfo.dealPrice;
      dispatch(removeDish(payload));
    }

    mixpanel.track("dish-removed");
    // clevertap.event.push("dish-removed");
  };

  const onChefNameClick = () => {
    dispatch(chefActions.onExploreChefClick(props.chefId));
    dispatch(bottomBarActions.loadTab({ tabName: "chef" }));
  }

  useEffect(() => {
    if (!props.id) return
    dispatch(fetchDishBand(props.id));
  }, [dispatch]);

  useEffect(() => {
    if (!loaded && props.isActive) {
      action('pause');
    }
  }, [loaded]);

  useEffect(() => {
    if (!isPaused && !loaded) {
      action('pause');
    }
  }, [isPaused, loaded])

  useEffect(() => {
    if (loaded && props.isActive) {
      action('play');
    }
  }, [loaded]);

  const imageLoaded = (time = 0) => {
    setTimeout(() => {
      setLoaded(true);
    }, time);
  };

  const getStatusClassname = () => {
    if (!isServiceable) {
      return "chef-non-serviciable";
    } else if (todayChefSchedule?.online) {
      return "chef-online";
    } else {
      return "chef-away";
    }
  };

  const isScheduleOrder = !isScheduleNow && moment(scheduleDateTime).isValid();
  const currentPriceValue = calculateCurrentPrice(dishInfo, currentChefsTime, currentChefDetails, isScheduleOrder, discountType, dishSpecialType);

  useEffect(() => {
    const isScheduleOrder = !isScheduleNow && moment(scheduleDateTime).isValid();
    const currentPriceValue = calculateCurrentPrice(dishInfo, currentChefsTime, currentChefDetails, isScheduleOrder, discountType, dishSpecialType);
    if (!!currentPriceRef?.current) return
    if (currentPriceValue === currentPriceRef?.current) return
    setCurrentPrice(currentPriceValue)
    currentPriceRef.current = currentPriceValue;
  }, [isScheduleNow, scheduleDateTime, dishInfo, currentChefsTime, currentChefDetails, user.maxDishes, user.totalDishesBooked]);


  const todayChefSchedule = getTodayDateValue(currentChefDetails?.schedules || []);

  const textOutput = getDisplayChefTimeLabel(todayChefSchedule);
  const percentageDiscount = ((props.price - currentPriceValue) / props.price) * 100;

  const discountMessage = `Voila! You got ${Math.round(percentageDiscount)}% off`;


  const VideoPlayerWrapper = () => {
    const onReady = () => {
      imageLoaded(500)
    };
    return <VideoPlayer videoUrl={S3_MENU_FOLDER + props.img} loaded={loaded} isPlaying={props.isActive && !isPaused} onReady={onReady} seekTimeRef={seekTimeRef} seekTime={seekTime} setSeekTime={setSeekTime} />;
  };

  return (
    <div>
      <div style={{ position: "absolute", top: "0px", height: "100%" }} className="image-container">
      {<>
          {props.img.includes("mp4") ? <VideoPlayerWrapper /> : <img
            src={S3_MENU_FOLDER + props.img}
            className="story-image-container"
            loading="lazy"
            onLoad={() => imageLoaded(0)}
          />}
          {storyImages.map((img) => {
            if (!img.includes("mp4")) {
              return <img
                src={S3_MENU_FOLDER + img}
                loading="lazy"
                className="hide-story-image"
              />
            }
          })} </>}
      </div>
      {!loaded &&
        <ImageLoader className="loader_wrapper explore_loader_wrapper" />
      }
      <div className="swiper_Top explore_swiper_Top">
        <div className="swiper_Title">
          <p className="exploreDishNameTitle" style={{ textTransform: "capitalize" }}>{`${name}`} <span style={{ color: "#D9D9D9" }}>{`\u2022`}</span> {`${headers}`}</p>
        </div>
        <div className="chef-info-name-wrapper">
          <div onClick={onChefNameClick} className="swiper_Title_img explore_swiper_Title_img">
            <img src={currentChefDetails?.chefImgTh || "https://cuirato-images.s3.ap-south-1.amazonaws.com/display-pic/thumbnail/chef3-dp.jpg"} className="storyImg" />
          </div>
          <div>
            <p onClick={onChefNameClick} className="explore-chef-label" >Chef {chefName}</p>
            <div className="chef-time-wrapper">
              <div style={{ marginTop: 0 }} className="explore-chef-status-indicator">
                <div
                  className={getStatusClassname()}
                />
              </div>
              <span className="explore-chef-timing-label" style={{}}>
                {textOutput}
              </span>
            </div>
          </div>
          <button onClick={handleMenuClick} className="explore-story-menu-button">Menu</button>
        </div>
        <div className="swiper_top_menu">
          <div className="swiper_top_back_btn">
          </div>
        </div>
      </div>
      <div style={{ padding: 0 }} className="swipper_bottom">
        <div className="ingrident-list-top-wrapper" />
        <div className="explore-swipper_bottom">
          <div className="ingrident-list-wrapper">
            <div className="ingrident-list-header-label">
              {labels.map((l, i) => {
                return <span key={i} className={(l.key === labelDesc.key) && "ingrident-list-header-label-active"}>{l.key}</span>
              })}
            </div>
            <div style={{ fontSize: 12 }}>
              {labelDesc?.desc}
            </div>
          </div>
          <div className="explore_swipper_bottom_first">
            <div>
              <div className="items_value">
                <div className="plus_taxes">
                  <h5 style={{ fontSize: 18 }}>
                    <span className="currency">&#8377;</span> <span className={(!!currentPriceValue && !currentPriceValue == 0) && "price-strike"}>{props.price}</span>
                    {(!!currentPriceValue && !currentPriceValue == 0) && <>{` ${currentPriceValue}`}</>}
                  </h5>
                </div>
              </div>
            </div>
            <div className="addcart_btn explore-addcart_btn">
              {!isDishAddedToCart ? <button onClick={() => addToCard(dishInfo._id)}>
                <span style={{ marginRight: 5 }}>+</span>
                <p style={{ fontSize: "18px" }}>ADD</p>
              </button> :
                <button style={{ padding: "0px 8px 0px 8px" }}>
                  <span onClick={() => onRemoveDish(dishInfo._id)}>-</span>
                  <span style={{ fontSize: "18px", lineHeight: "22px" }}>{currentDishCount}</span>
                  <span onClick={() => addToCard(dishInfo._id)}>+</span>
                </button>
              }
            </div>
          </div>
          {discountMessage && (
            <h6 style={{ fontSize: 14, padding: "0px 25px" }} className="media-dish-cap orange-text">
              {discountMessage}
            </h6>
          )}
        </div>
      </div>
    </div>
  );
});

export default ExploreStory;