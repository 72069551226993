import { useState, useEffect } from "react";
import CustomizeBottomSlider from "../customizeBottomSlider/Index";
import RemoveDishPortion from "../customizeBottomSlider/RemoveDishPortion";



const DishCheckPrice = ({
    showAdd, setShowAdd, showAdded, portion, setShowAdded, showSchedule, onAddDish, onRemoveDish, updateDish, percentageDiscount, discountedPrice, dishBand, dish, cartData,
}) => {
    const { isFromCart } = dish;
    const [isOpen, setOpen] = useState(false);
    const [isPortionBottonOpen, setPortionBottonOpen] = useState(false);

    const handleAddToCartClick = () => {
        if (dish?.dish?.customizeObj?.length && !isFromCart) {
            setOpen(true)
        } else {
            onAddDish(dish.id, 0, [], dish?.dish?.cartId)
        }
    }

    const onRemoveDishClick = () =>{
        const currentDishesCartData = cartData?.filter((order) => order._id === dish.id);
        
        if(!isFromCart && currentDishesCartData.length > 1){
            setPortionBottonOpen(true)
        }else{
            onRemoveDish(
                dish.id,
                setShowAdd,
                setShowAdded,
                totalCartAddedDishCount,
                dish?.dish?.cartId
            )
        }
    }

    const dishPortion = dish?.dish?.portion || [];
    const dishInfo = dish?.dish;
    const cartScreenPortion = dish.dish?.portion || [];
    const categoryScreenPortion = cartData?.filter((order) => order._id === dish.id).reduce((prev, curr) => curr?.portion + prev, 0) || 0

    const totalCartAddedDishCount = isFromCart ? cartScreenPortion : categoryScreenPortion;
    
    useEffect(() => {
        if (!showAdd &&
            !showAdded &&
            !showSchedule &&
            !dish.unAvailable) {
            updateDish(
                dish.id,
                setShowAdd,
                dish.price,
                dish.dish.ingredientCost,
                dish.dish.fuelCost,
                dish.dish.packagingCost,
                dish.dish.deliveryCost,
                dishBand.band
            )
        }

    }, [showAdd, showAdded, showSchedule, dish.unAvailable])
    return (
        <>
        <div className="dish-add-wrapper">
            {!showAdd &&
                !showAdded &&
                !showSchedule &&
                !dish.unAvailable ? (
                <button
                    type="button"
                    className={
                        dish.img
                            ? "btn btn-add check-price-btn"
                            : "btn btn-add check-price-btn bottom1"
                    }
                    onClick={() =>
                        updateDish(
                            dish.id,
                            setShowAdd,
                            dish.price,
                            dish.dish.ingredientCost,
                            dish.dish.fuelCost,
                            dish.dish.packagingCost,
                            dish.dish.deliveryCost,
                            dishBand.band
                        )
                    }
                >
                    <span>Check Price</span>
                    <img
                        src="images/Icons/Icon-06.svg"
                        alt="search-item"
                        loading="lazy"
                    />
                </button>
            ) : (
                ""
            )}
            {showAdd && !showSchedule && !dish.unAvailable ? (
                <button
                    type="button"
                    className={
                        dish.img ? "btn btn-add" : "btn btn-add bottom1"
                    }
                    onClick={handleAddToCartClick}
                >
                    <span>Add to Cart</span>
                    <img
                        src="images/icon-plus.svg"
                        alt="search-item"
                        loading="lazy"
                    />
                </button>
            ) : (
                ""
            )}

            {dish.unAvailable && !showSchedule && (
                <button type="button" className="btn btn-add">
                    <span>unavailable</span>
                    <img
                        src="images/access-denied-icon.svg"
                        alt="search-item"
                        loading="lazy"
                    />
                </button>
            )}

            {showAdded && !showSchedule && !dish.unAvailable ? (
                <div
                    className={
                        dish.img
                            ? "input-group add-items"
                            : "input-group add-items bottom1 ml-2"
                    }
                >
                    <button
                        className="btn btn-minus-item"
                        onClick={onRemoveDishClick}
                    >
                        <img
                            src="images/icon-minus.svg"
                            alt="search-item"
                            loading="lazy"
                        />
                    </button>
                    <div>{totalCartAddedDishCount}</div>
                    <button
                        className="btn btn-add-item"
                        onClick={() => handleAddToCartClick(dish.id)}
                    >
                        <img
                            src="images/icon-plus.svg"
                            alt="search-item"
                            loading="lazy"
                        />
                    </button>
                </div>
            ) : (
                ""
            )}
            {showSchedule && dish.isScheduleActive ? (
                <div className="input-group add-items item-schedule">
                    <button
                        type="button"
                        className="btn btn-added btn-schedule"
                    >
                        <span> Schedule </span>
                    </button>
                    <img src="images/time-period.svg" />
                </div>
            ) : (
                ""
            )}
            {!isFromCart && <CustomizeBottomSlider isOpen={isOpen} discountedPrice={discountedPrice} dishId={dish.id} onAddDish={onAddDish} percentageDiscount={percentageDiscount} setOpen={setOpen} dishPortion={dishPortion} dishInfo={dishInfo} />}
            {!isFromCart && <RemoveDishPortion dishPortionDetails={cartData?.filter((order) => order._id === dish.id)} isOpen={isPortionBottonOpen} discountedPrice={discountedPrice} dishId={dish.id} onAddDish={onAddDish} percentageDiscount={percentageDiscount} setOpen={setPortionBottonOpen} setShowAdd={setShowAdd}
                setShowAdded={setShowAdded} totalCartAddedDishCount={totalCartAddedDishCount} onRemoveDish={onRemoveDish} dishPortion={dishPortion} dishInfo={dishInfo} />}
        </div>
        {!!dish?.dish?.customizeObj?.length && <div className="customisable-dish-menu-label">Customisable</div>}
        </>
    );
}

export default DishCheckPrice;
