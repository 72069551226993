import axios from "axios";
import { chefActions } from "./chefSlice";
import { API_LOCAL, API_TEST, EVENING_SHIFT_END_TIME, EVENING_SHIFT_START_TIME } from "./constants";

export const getKitchenStatus = () => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axios.get("getKitchenStatus");
      if (response.status != 200) {
        throw new Error("Could not fetch Chef list");
      }
      const data = response.data.data;
      return data;
    };

    try {
      const data = await fetchData();
      dispatch(chefActions.setKitchenStatus(data));
    } catch (error) {
      console.log("error while calling getKitchenStatus");
      // show error on ui
    }
  };
};

export const fetchChefList = () => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axios.get("chef");
      if (response.status != 200) {
        throw new Error("Could not fetch Chef list");
      }
      const data = response.data.data;
      return data;
    };

    try {
      const data = await fetchData();
      const now = new Date().getHours();
      let sortedData = [];
      if (now >= EVENING_SHIFT_START_TIME || now < EVENING_SHIFT_END_TIME) {
        sortedData = data.sort((a, b) => a.shift - b.shift || a.listRank - b.listRank);
      } else {
        sortedData = data.sort((a, b) => b.shift - a.shift || a.listRank - b.listRank);
      }
      dispatch(chefActions.replaceChefList({ chefList: sortedData }));
    } catch (error) {
      console.log("error while calling chef-fetchdata");
      // show error on ui
    }
  };
};

const getWeekNumberInMonth = (date) => {
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

  const weekNumber = Math.ceil((date.getDate() + firstDayOfMonth.getDay()) / 7);
  return weekNumber;
}

export const fetchCategories = (chefId) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axios.get("menu/chef/categories/" + chefId);
      if (response.status != 200) {
        throw new Error("Could not fetch Chef list");
      }
      const data = response.data.data;
      return data;
    };
    const currentDate = new Date();
    const currentWeekdayNumber = currentDate.getDay();

    try {
      const data = await fetchData();
      const categoriesList = data.categories.map((item) => {
        const allDishes = [...item.dishes]
        if (item?.type == "daily") {
          const currentDishes = allDishes.find((a) => a.weekDay === currentWeekdayNumber);
          item.dishes = currentDishes?.dishes || []
        } else if (item?.type == "weekly") {
          const currentDate = new Date();
          const weekNumberInMonth = getWeekNumberInMonth(currentDate);
          const currentDishes = allDishes.find((a) => a.weekNumber === weekNumberInMonth);
          item.dishes = currentDishes?.dishes || []
        }
        return item;
      });
      const filterCategoriesList = categoriesList.filter((a) => a.dishes.length);
      dispatch(
        chefActions.replaceCategories({
          categories: filterCategoriesList,
          chefId: chefId,
          id: data._id
        })
      );
    } catch (error) {
      console.log("error while calling categories-fetchdata");
      // show error on ui
    }
  };
};

export const fetchChefWaitingTime = (chefId) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axios.get("getDeliveryDelayTimeByChef/" + chefId);
      if (response.status != 200) {
        throw new Error("Could not fetch Chef Time");
      }
      const data = response.data.data;
      return data;
    };

    try {
      const data = await fetchData();
      dispatch(
        chefActions.setChefWaitingTime({
          time: data,
          chefId: chefId,
        })
      );
    } catch (error) {
      console.log("error while calling categories-fetchdata");
      // show error on ui
    }
  };
};

