import React, { useEffect, useState } from 'react';
import Sheet from 'react-modal-sheet';
import "./customizeBottomSlider.css"
import { Checkbox } from 'antd';
import CloseCrossSvg from '../../assets/ReactSvg/CloseCrossSvg';


const VegNonVegLogo = ({ type, size, hideText }) => {
    return <span className='vegnonVegLogo'>
        {type?.toLowerCase() === "VEG".toLowerCase() && (
            <span className="food-list-item veg saff">
                <figure className='vegnonvegFigure'>
                    <img style={size ? { height: size } : {}} src="images/icon-veg.svg" alt="veg" loading="lazy" />
                    {!hideText && <span style={{ textTransform: "uppercase", marginTop: 3 }}>{type}</span>}
                </figure>
            </span>
        )}
        {type?.toLowerCase() === "EGG".toLowerCase() && (
            <span className="food-list-item egg saff">
                <figure className='vegnonvegFigure'>
                    <img style={size ? { height: size } : {}} src="images/icon-egg.svg" alt="egg" loading="lazy" />
                    {!hideText && <span style={{ textTransform: "uppercase" }}>{type}</span>}
                </figure>
            </span>
        )}
        {type?.toLowerCase() === "NON VEG".toLowerCase() && (
            <span className="food-list-item non-veg saff">
                <figure className='vegnonvegFigure'>
                    <img
                        style={size ? { height: size } : {}}
                        src="images/icon-non-veg.svg"
                        alt="non-veg"
                        loading="lazy"
                    />
                    {!hideText && <span style={{ textTransform: "uppercase" }}>{type}</span>}
                </figure>
            </span>
        )}
    </span>
}

const transformData = (inputData) => {
    return inputData.map(item => {
        let newItem = {
            label: item.label,
            isOptional: item.isOptional
        };
        if (item.options && item.options.length > 0) {
            let selectedOption = item.options.find(option => option.isSelected);
            if (selectedOption) {
                newItem.text = selectedOption.text;
                if (selectedOption.extraPrice) {
                    newItem.extraPrice = selectedOption.extraPrice;
                }else{
                    newItem.extraPrice = 0;
                }
            }
        }
        return newItem;
    });
}

const CustomizeBottomSlider = ({ isOpen, setOpen, discountedPrice, dishId, onAddDish, dishPortion = [], dishInfo, percentageDiscount }) => {

    const [customizeObj, setCustomizeObj] = useState([]);

    const customizeObjValue = dishInfo?.customizeObj || [];

    useEffect(() => {
        if (isOpen) {
            setCustomizeObj(transformData(customizeObjValue))
        }
    }, [isOpen])

    const onCheckBoxChange = (value, label) => {
        const currentValue = customizeObjValue.find((a) => a.label === label)
        const index = customizeObjValue.findIndex((a) => a.label === label)
        const prevCustomizeObj = [...customizeObj];
        prevCustomizeObj[index].text = value[0];
        const selectedOption = currentValue.options.find((o) => o.text === value[0])
        if (selectedOption?.extraPrice) {
            prevCustomizeObj[index].extraPrice = selectedOption?.extraPrice;
        } else {
            prevCustomizeObj[index].extraPrice = 0;
        }
        setCustomizeObj(prevCustomizeObj)
    }

    const quantityTextLabel = customizeObj?.length ? customizeObj.map(item => item.text).join(' ').trim() : "";

    const extraPrice = customizeObj.reduce((sum, item) => {
        if (item.hasOwnProperty('extraPrice')) {
            return sum + item.extraPrice;
        }
        return sum;
    }, 0);

    const isValidCustomize = customizeObj.filter((a) => !a.isOptional).every((a) => a.text)

    return (
        <Sheet
            springConfig={{
                stiffness: 300,
                damping: 30,
                mass: 0.2
            }}
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            detent="content-height"
            onSnap={(snapIndex) =>
                console.log("> Current snap point index:", snapIndex)
            }
        >
            <Sheet.Container>
                <Sheet.Content>
                    <div className='closeButtonWrapper'>
                        <div onClick={() => setOpen(false)} className='closeButtonBox'>
                            <CloseCrossSvg />
                        </div>
                    </div>
                    <div style={{ padding: "0px 30px 0px 30px", backgroundColor: "#FFFFFF", borderRadius: "50px 50px 0px 0px" }}>
                        <div className='dishInfoPortionSelector'>
                            <div><VegNonVegLogo type={dishInfo?.type} /></div>
                            <div className='customizeLabel'><h6 className='dishInfoPortionSelectorHeader'>customize "{dishInfo?.name}"</h6>
                                <span style={{ color: "#808285", position: "relative", top: 4, fontSize: 13 }} className=''>₹{discountedPrice}</span></div>
                        </div>
                        <div className='customizeDescContainer'>
                            {customizeObjValue.map((c) => {
                                const currentObjectText = customizeObj.find((a) => a.label === c.label)?.text || "";
                                return (<div className='dishInfoPortionBoxWrapper'>
                                    <div className='dishInfoPortionWrapper'><h6 className='dishInfoPortionSelectorHeader'>{c.label}</h6>{c.isOptional && <span className='optionalText'>(optional)</span>}</div>
                                    <div className='dishInfoPortionBottomWrapper'>
                                        <div className='dishInfoVegLogoWrapper'>
                                            {c.options.map((option) => (
                                                <div>
                                                    <VegNonVegLogo type="veg" size={14} hideText={true} />
                                                </div>
                                            ))}
                                        </div>
                                        <Checkbox.Group onChange={(e) => onCheckBoxChange(e, c.label)} className='dishPortioncheckbox' options={c.options.map((o) => o.text)} value={currentObjectText} />
                                        {!!c.options.filter((a) => !!a.extraPrice).length &&
                                            <div style={{position:"relative", right:18}} className='dishInfoVegLogoWrapper'>
                                                {c.options.map((option) => (
                                                    <span className='extraPriceLabel'>(₹{option.extraPrice})</span>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>
                    <div className='customizeBottomSlideBarWrapper'>
                        {!!quantityTextLabel && <div className='customizeTextBoxWrapper'>
                            <span>{quantityTextLabel}</span>
                        </div>}
                        <div className='customizeBottomSlideBar'>
                            <button
                                style={{ padding: "16px 16px", borderRadius: 0, fontWeight: 800, fontSize: 12, backgroundColor: "#E8E8E8" }}
                                className="scheduleDeliveryConfirmBtn"
                            >
                                <div className='scheduleTotalTitleSumWrapper'>
                                    <span className='scheduleTotalTitleSum'>₹ {(discountedPrice + extraPrice).toFixed(2)}</span>
                                    <span className='scheduleTotalTitle'>TOTAL</span>
                                </div>
                            </button>
                            <button
                                onClick={() => {
                                    onAddDish(dishId, extraPrice, customizeObj)
                                    setOpen(false)
                                }}
                                disabled={!isValidCustomize}
                                style={{ padding: "16px 16px", borderRadius: 0, fontWeight: 800, fontSize: 12 }}
                                className="scheduleDeliveryConfirmBtn addItemScheduleDeliveryConfirmBtn"
                            >
                                ADD ITEM
                            </button>
                        </div>
                    </div>
                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop onClick={() => setOpen(false)} />
        </Sheet>
    )
}

export default CustomizeBottomSlider
