import { getToken } from "@firebase/messaging";
import moment from "moment";


export const getUniqueArray = (array) => {
  const uniqueIds = [];
  return array.filter((obj) => {
    if (!uniqueIds.includes(obj.cartId)) {
      uniqueIds.push(obj.cartId);
      return true;
    }
    return false;
  });
};

export const isWebApp = () => {
  const isStandalone =
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone === true;
  const isHTTPS = window.location.protocol === "https:";
  return !isStandalone && isHTTPS;
};

export const getExactTime = (timestamp, moreMinutes, isOrderScheduled) => {
  const targetTime = new Date(timestamp);
  if (moreMinutes) {
    targetTime.setMinutes(targetTime.getMinutes() + moreMinutes);
  }
  const adjustedTimestamp = new Date(targetTime);
  if (isOrderScheduled) {
    adjustedTimestamp.setHours(targetTime.getHours() - 5);
    adjustedTimestamp.setMinutes(targetTime.getMinutes() - 30);
  }
  const momentTimeFormate = isOrderScheduled ? moment(adjustedTimestamp).format("MMM D, h:mm A") : moment(targetTime).format("h:mm A");
  return `${momentTimeFormate}`;
};

export const getOrderStatusLabel = (orderStatus) => {
  switch (orderStatus) {
    case "CREATED":
      return "Order Placed";
    case "CONFIRMED":
      return "Order Confirmed";
    case "READY":
      return "Order Ready";
    case "PICKED_UP":
      return "Order on the way";
    case "DELIVERED":
      return "Delivered";
    default:
      return "Preparing";
  }
};

export const combineTheDate = (date1, date2) => {
  const year = date1?.year();
  const month = date1?.month();
  const day = date1?.date();

  // Extract time components from date2
  const hours = date2?.getHours();
  const minutes = date2?.getMinutes();
  const seconds = date2?.getSeconds();

  // Create a new Date object with combined date and time
  const newDate = new Date(year, month, day, hours, minutes, seconds);
  return newDate;
};


export const isIOSDevice = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

export const capitalizeFirstLetter = (string = "") => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

export const mergeCartData = (stateCartData, payloadCartData) => {
  return payloadCartData.map((data) => {
    const existingIndex = stateCartData.findIndex(
      (stateItem) => stateItem._id === data._id
    );
    if (existingIndex !== -1) {
      if (stateCartData[existingIndex]?.dealPrice) {
        data.dealPrice = stateCartData[existingIndex]?.dealPrice;
      }
    }
    return data;
  });
};

const calculateDiscountedPrice = (originalPrice, percentageOff) => {
  const discount = originalPrice * (percentageOff / 100);
  const discountedPrice = originalPrice - discount;
  return discountedPrice;
}

const getDishPriceBasedOnTime = (prepTime, originalPrice, scheduleOrder, discountType, dishSpecialType = "") => {
  if (dishSpecialType === "daily") {
    const fullDiscount = discountType === "deepDiscount" ? 90 : 80;
    const midDiscount = discountType === "deepDiscount" ? 80 : 70;
    const lessDiscount = discountType === "deepDiscount" ? 70 : 60;
    if (prepTime <= 31 || scheduleOrder) {
      return calculateDiscountedPrice(originalPrice, 100)
    } else if (prepTime < 45) {
      return calculateDiscountedPrice(originalPrice, 100)
    } else {
      return calculateDiscountedPrice(originalPrice, 100)
    }
  } else {
    const fullDiscount = discountType === "deepDiscount" ? 75 : 50;
    const midDiscount = discountType === "deepDiscount" ? 67.5 : 25;
    const lessDiscount = discountType === "deepDiscount" ? 50 : 0;
    if (prepTime <= 31 || scheduleOrder) {
      return calculateDiscountedPrice(originalPrice, fullDiscount)
    } else if (prepTime < 45) {
      return calculateDiscountedPrice(originalPrice, midDiscount)
    } else {
      return calculateDiscountedPrice(originalPrice, lessDiscount)
    }
  }
}

export const calculateCurrentPrice = (dish, currentChefsTime, currentChefDetail, scheduleOrder = false, discountType, dishSpecialType = "") => {
  const dishPrepTime = Math.max(currentChefsTime?.chefWaitTime, currentChefDetail?.prepTime);
  const dishMargin = dish.margin;
  const dishPrice = dish.price;
  const dishBaseCost = dishPrice - dishMargin;
  const currentPrice = getDishPriceBasedOnTime(dishPrepTime, dishMargin, scheduleOrder, discountType, dishSpecialType) + dishBaseCost;
  return currentPrice;
};

export const flatten2DArray = (array) => {
  return array.reduce((acc, row, rowIndex) => {
    row.dishes?.forEach((value, colIndex) => {
      const index = colIndex * array.length + rowIndex;
      acc[index] = { ...value, categoryHeader: row?.name, dishSpecialType: row?.type, categoryImg: row?.img, categoryId: row?._id };
    });
    return acc;
  }, []);
}

export const getNextStoryImages = (array) => {
  const result = array.map((item, index) => {
    const storyImages = item.stories.map((story) => story.img);
    const nextItem = array[index + 1];

    // Check if nextItem is defined and has stories array
    const nextTwoStoryImages = nextItem && nextItem.stories
      ? nextItem.stories.slice(0, 1).map((story) => story.img || "")
      : [];
    
    return {
      ...item,
      storyImages: [...storyImages, ...nextTwoStoryImages],
    };
  });
  return result
}

export const sortedDishArray = (dishArray) => {
  return dishArray.sort((a, b) => {
    if (a.dishSpecialType < b.dishSpecialType) {
      return -1;
    } else if (a.dishSpecialType > b.dishSpecialType) {
      return 1;
    } else {
      return 0;
    }
  });
}