import { useDispatch, useSelector } from "react-redux";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { cartAction } from "../../store/cartSlice";
import MenuTopBar from "../menu/MenuTopBar";
import AppContainer from "../util/AppContainer";
import "./address.css";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useEffect, useState } from "react";
import { FREE_DILIVERY_DISTANCE_RANGE, MINIMUM_AMOUNT_MAX_RANGE, MINIMUM_AMOUNT_MIN_RANGE, ORDER_DISTANCE_RANGE, ORDER_DISTANCE_RANGE_MIN } from "../../store/constants";
import { loginActions } from "../../store/loginSlice";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import Script from "react-load-script";
import { useRef } from "react";
// import clevertap from "clevertap-web-sdk";

const Address = () => {
  const sourceLocation = {
    lat: 19.116606,
    lng: 72.8854982,
  };

  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: "/home" });
    ReactGA.event({
      category: "tab",
      action: "address",
    });

    mixpanel.track("select-address");
    // clevertap.event.push("select-address");
  }, []);

  const dispatch = useDispatch();
  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  const onBackClick = () => {
    switchTab("cart");
  };

  const [selectedAddress, setSelectedAddress] = useState({});

  const user = useSelector((state) => state.auth.user);
  const addresses = user.addressLine1;
  const distanceMatrixRef = useRef(null);

  const onSelectAddressHandler = (data) => {
    distanceMatrixRef.current = new window.google.maps.DistanceMatrixService();

    distanceMatrixRef.current.getDistanceMatrix(
      {
        origins: [sourceLocation],
        destinations: [
          {
            lat: data?.location?.lat,
            lng: data?.location?.lng,
          },
        ],
        travelMode: "DRIVING",
      },
      (res, status) => {
        if (status !== "OK") {
          console.log("Unable to fetch...");
        } else {
          let delivery_distance = res.rows[0].elements[0].distance.value;
          let delivery_duration = res.rows[0].elements[0].duration;
          let deliveryObject = {
            delivery_distance,
            delivery_duration,
            kitchenLocation: sourceLocation
          };
          dispatch(loginActions.setDeliveryDetails(deliveryObject));
          let deliveryDistanceInKMS = Math.round(delivery_distance / 1000);
          if (deliveryDistanceInKMS > ORDER_DISTANCE_RANGE) {
            dispatch(loginActions.setIsServiceable(false));
            dispatch(loginActions.setUnserviceableModalOnCart(true));
            dispatch(loginActions.setMinimumAmount(0));
          } else if (deliveryDistanceInKMS > ORDER_DISTANCE_RANGE_MIN && 
            deliveryDistanceInKMS < ORDER_DISTANCE_RANGE) {
            dispatch(loginActions.setIsServiceable(true));
            dispatch(loginActions.setFarAwayModalOnCart(true));
            dispatch(loginActions.setMinimumAmount(MINIMUM_AMOUNT_MAX_RANGE));
          } else if (deliveryDistanceInKMS > FREE_DILIVERY_DISTANCE_RANGE && 
            deliveryDistanceInKMS < ORDER_DISTANCE_RANGE_MIN) {
              dispatch(loginActions.setIsServiceable(true));
              dispatch(loginActions.setMinimumAmount(MINIMUM_AMOUNT_MIN_RANGE));
          } else {
            dispatch(loginActions.setIsServiceable(true));
            dispatch(loginActions.setMinimumAmount(0));
          }
          dispatch(loginActions.setSelectedAddress(data));
          dispatch(cartAction.setIsAddressOptionsVisible(false));
          switchTab("cart");
        }
      }
    );
  };

  return (
    <>
      <Script
        url="https://maps.googleapis.com/maps/api/js?key=AIzaSyDSrNgyDHSkqDzUMNIJEbsLt3u_q1RbjYw&libraries=places"
        onLoad={onSelectAddressHandler}
      />
      <AppContainer>
        <div className="address-wrapper">
          <div className="address-top-bar">
            <MenuTopBar
              name="Select Delivery Address"
              notMenu={true}
              onBackClick={onBackClick}
            />
          </div>
          <div className="address-card_wrapper">
            {addresses.length
              ? addresses.map((data) => {
                  return (
                    <>
                      <div
                        key={data._id}
                        className="address_card"
                        onClick={() => {
                          onSelectAddressHandler(data);
                          setSelectedAddress(data);
                        }}
                      >
                        <div className="tag_container">
                          <h6>{data.tag}</h6>
                          <LocationOnIcon
                            className={
                              selectedAddress?._id == data._id ? "selected" : ""
                            }
                          />
                        </div>
                        <p style={{ width: "90%" }}>
                          {data.location.locationName}
                        </p>
                      </div>
                    </>
                  );
                })
              : "No Saved Addresses"}
          </div>

          <div className="select_btn">
            {!addresses.length && (
              // (
              //   <button
              //     onClick={() => {
              //       dispatch(cartAction.setIsAddressOptionsVisible(false));
              //       switchTab("cart");
              //     }}
              //     className="address-form_actionbtn"
              //   >
              //     CONTINUE
              //   </button>
              // ) :

              <button
                onClick={() => {
                  dispatch(cartAction.setIsAddressOptionsVisible(false));
                  switchTab("addressForm");
                }}
                className="address-form_actionbtn"
              >
                ADD ONE
              </button>
            )}
          </div>
        </div>
      </AppContainer>
    </>
  );
};

export default Address;
